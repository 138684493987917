import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import useResizeAware from "react-resize-aware";
import classNames from "classnames";
import {
    AllowStretchingButton,
    RestoreProportionsButton,
    itemIsImageUnreplacedPlaceholder,
    useSelection,
    useShowContextualToolbarFloating
} from "@designer-suite";
import { SCROLLBAR_WIDTH, getSelectedItemTypes } from "@utilities";
import { isDebugMode } from "@shared/utils/Debug";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { useAppSelector } from "@shared/redux";
import { useXerox } from "@shared/features/CompetitiveBaselining";
import { BackButton, MoreButton } from "@shared/features/ContextualToolbar";
import { SubselectionType, useTableSubselection } from "@shared/features/Tables";
import { generateContextualToolbarFloatingTools } from "./ToolbarComponents/generateContextualToolbarFloatingTools";
import { ToolbarGroupsConfig, ToolgroupConfig, ToolgroupTypes } from "./ToolbarComponents/ToolbarGroupsConfig";
import { ItemDebugButton } from "./ToolbarComponents/Buttons/ItemDebugButton";
import type { ItemSelection } from "./@types/designer";
import "./contextualToolbarFloating.scss";

const getToolbarType = (selectedItems: ItemSelection, tableSubselectionType: SubselectionType) => {
    const types = getSelectedItemTypes(selectedItems);

    if (types.length === 1) {
        switch (types[0]) {
            case ItemTypes.TEXT:
            case ItemTypes.WORDART:
                return ToolgroupTypes.WordArtAndTextToolbar;
            case ItemTypes.IMAGE:
                if (itemIsImageUnreplacedPlaceholder(selectedItems[0])) {
                    return ToolgroupTypes.ImagePlaceholderToolbar;
                }
                return ToolgroupTypes.ImageToolbar;
            case ItemTypes.SHAPE:
                return ToolgroupTypes.ShapeToolbar;
            case ItemTypes.TABLE:
                // Resets tooolbar if subselection is picked, otherwise showMore may be false when
                // switching back to the main table
                if (tableSubselectionType === SubselectionType.None) {
                    return ToolgroupTypes.TableToolbar;
                }
                return ToolgroupTypes.TableSubselectionToolbar;
            case ItemTypes.QR_CODE:
                return ToolgroupTypes.QRCodeToolbar;
            case ItemTypes.TEAMS:
                return ToolgroupTypes.TeamsToolbar;
            default:
                return ToolgroupTypes.DefaultToolbar;
        }
    } else if (types.length === 2 && types.includes(ItemTypes.TEXT) && types.includes(ItemTypes.WORDART)) {
        return ToolgroupTypes.WordArtAndTextToolbar;
    } else {
        return ToolgroupTypes.DefaultToolbar;
    }
};

export function ContextualToolbarFloating() {
    const [toolbarResizeListener, toolbarSizes] = useResizeAware();
    const [containerResizeListener, containerSizes] = useResizeAware();
    const [isShowingHiddenItems, setIsShowingHiddenItems] = useState(false);
    const showValidationPanel = useAppSelector(state => state.showValidations.showPanel);

    const items = useSelection();
    const containerMeasured = containerSizes.width !== null;
    // Only show the contextual toolbar after the container has been rendered and measured
    const showContextualToolbarFloating = useShowContextualToolbarFloating() && containerMeasured;
    const { isXerox } = useXerox();
    const {
        selection: { type: tableSubselectionType }
    } = useTableSubselection();
    const toolbarType = getToolbarType(items, tableSubselectionType);

    const [tools, setTools] = useState<ToolbarGroupsConfig>({
        visible: [],
        hidden: [],
        showMore: true,
        items: []
    });
    const moreButtonRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!toolbarType) {
            return;
        }
        setIsShowingHiddenItems(false);

        const toolgroup = Object.entries(generateContextualToolbarFloatingTools(toolbarType, isXerox));
        const visibleComponents = toolgroup.map(([key, component]: [string, JSX.Element]) => {
            const toolRef = React.createRef<HTMLDivElement>();
            return {
                key,
                toolRef,
                component
            };
        });
        setTools({
            visible: visibleComponents,
            hidden: [],
            showMore: false,
            items: visibleComponents
        });
    }, [isXerox, toolbarType]);

    // TODO: this logic causes a flash sometimes - color picker is rendering late
    useLayoutEffect(() => {
        if (isShowingHiddenItems) return;

        if (items.length > 0) {
            const moreButtonNode = moreButtonRef.current;

            if (toolbarSizes.width && containerSizes.width && tools.items) {
                // take into account the possibility of the scrollbar
                const containerWidth = containerSizes.width - SCROLLBAR_WIDTH;

                const moreButtonWidth = moreButtonNode?.offsetWidth ?? 0;

                let visibleItemsWidth = 0;

                const toolbarGrouped = tools.items.reduce(
                    (
                        accumulator: { visible: ToolgroupConfig[]; hidden: ToolgroupConfig[] },
                        currentItem: ToolgroupConfig,
                        currentIndex: number,
                        sourceItems: ToolgroupConfig[]
                    ) => {
                        const currentItemRef = currentItem.toolRef.current;

                        if (!currentItemRef) return accumulator;

                        const { visible, hidden } = accumulator;
                        const isLast = currentIndex === sourceItems.length - 1;
                        const visibleItemsWidthNext = visibleItemsWidth + currentItemRef.offsetWidth;

                        if (
                            // if I have elements that are hidden all the next elements should be hidden too
                            hidden.length === 0 &&
                            ((!isLast && visibleItemsWidthNext + moreButtonWidth < containerWidth) ||
                                (isLast && visibleItemsWidthNext < containerWidth))
                        ) {
                            visible.push(currentItem);
                            visibleItemsWidth = visibleItemsWidthNext;
                        } else {
                            hidden.push(currentItem);
                        }

                        return accumulator;
                    },
                    {
                        visible: [],
                        hidden: []
                    }
                );

                setTools({
                    ...tools,
                    visible: toolbarGrouped.visible,
                    hidden: toolbarGrouped.hidden,
                    showMore: toolbarGrouped.hidden.length > 0
                });
            } else {
                setIsShowingHiddenItems(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toolbarSizes.width, containerSizes.width, isShowingHiddenItems]);

    return (
        <div
            data-block-outside-panel-click-to-close
            className={classNames("easel-contextual-toolbar-floating", {
                "easel-contextual-toolbar-shown": showContextualToolbarFloating,
                "easel-contextual-toolbar-floating-open-panel": showValidationPanel
            })}
        >
            {containerResizeListener}
            <div className="easel-contextual-toolbar-container">
                {toolbarResizeListener}
                {items.length > 0 && (
                    <div className="easel-contextual-toolgroup-container" data-dcl-prevent-canvas-items-deselection>
                        <div className="easel-extra-buttons">
                            <RestoreProportionsButton showIcon />
                            <AllowStretchingButton showIcon />
                            {isDebugMode() && <ItemDebugButton />}
                        </div>

                        <div
                            className={classNames("easel-toolgroup", {
                                "easel-toolgroup-hidden": isShowingHiddenItems,
                                "easel-toolgroup-visible": !isShowingHiddenItems
                            })}
                        >
                            {tools.visible.map(tool => {
                                return (
                                    <div className="easel-contextual-tool" ref={tool.toolRef} key={tool.key}>
                                        {tool.component}
                                    </div>
                                );
                            })}
                        </div>

                        <div
                            ref={moreButtonRef}
                            className={classNames("easel-toolbar-more-button-container", {
                                "easel-toolbar-more-button-container-show": tools.showMore && !isShowingHiddenItems
                            })}
                        >
                            <MoreButton className="more-button" onClick={() => setIsShowingHiddenItems(true)} />
                        </div>

                        {tools.showMore && isShowingHiddenItems && (
                            <div className="easel-toolbar-back-button-container">
                                <BackButton className="back-button" onClick={() => setIsShowingHiddenItems(false)} />
                            </div>
                        )}

                        {tools.showMore && (
                            <div
                                className={classNames("easel-toolgroup", {
                                    "easel-toolgroup-visible": isShowingHiddenItems,
                                    "easel-toolgroup-hidden": !isShowingHiddenItems
                                })}
                            >
                                {tools.hidden.map(tool => {
                                    return (
                                        <div ref={tool.toolRef} key={tool.key} className="easel-contextual-tool">
                                            {tool.component}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
ContextualToolbarFloating.displayName = "ContextualToolbarFloating";
