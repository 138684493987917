import React from "react";
import { PremiumFinishMaskButton as PremiumFinishMaskButtonComponent } from "@presentational";
import { isPremiumFinishImageButtonCompatible } from "@utilities";
import { isCareAgent } from "@shared/utils/Care";
import { useDesigner } from "../../designer/DesignerProvider";
import { useSelection } from "../../designer/useSelection";
import { useActiveCanvas } from "../../designer/useActiveCanvas";
import { hasAdvancedTools } from "../../../utilities/Utilities";

export function PremiumFinishMaskButton() {
    const designer = useDesigner();
    const selection = useSelection(
        "model:change:premiumFinish model:change:premiumFinishMaskPreviewUrl change:fetchingPremiumFinishOverlay"
    );
    const canvas = useActiveCanvas();

    if (!isPremiumFinishImageButtonCompatible(designer, selection, canvas) || !(isCareAgent() || hasAdvancedTools())) {
        return null;
    }

    const onClick = () => {
        if (!designer) {
            return;
        }
        designer.eventBus.trigger("modal:premiumFinish");
    };

    return <PremiumFinishMaskButtonComponent iconOnly={true} onClick={onClick} />;
}
PremiumFinishMaskButton.displayName = "PremiumFinishMaskButton";
