import React from "react";
import { LayersToolExpandingButton } from "@presentational";
import { getTrackingDataForSelection } from "@utilities";
import { SubselectionType, useTableSubselection } from "@shared/features/Tables";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useDesigner } from "../designer/DesignerProvider";
import { useSelection } from "../designer/useSelection";
import { getCurrentCanvas, updateZIndex, disableChangingZIndex } from "../util";

interface Props {
    /** For overriding styles */
    className?: string;
    /** Whether the button should show an icon
     * @default false
     */
    showIcon?: boolean;
}

/**
 * Connected Layer Tool
 */
export function LayersTool({ className = "expanding-tool-icon", showIcon }: Props) {
    const designer = useDesigner();
    const selection = useSelection("model:change:zIndex");
    const selectedItem = selection[0];
    const {
        selection: { type: tableSubselectionType }
    } = useTableSubselection();

    if (!designer || selection.length !== 1 || tableSubselectionType !== SubselectionType.None) {
        return null;
    }

    const canvas = getCurrentCanvas(designer, selectedItem);

    const { disableBringForward, disableSendBackward } = disableChangingZIndex(selectedItem, canvas);

    return (
        <LayersToolExpandingButton
            onOpen={() => {
                designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_ARRANGE, getTrackingDataForSelection(selection));
            }}
            className={className}
            onClickBringToFront={updateZIndex("bringToFront", selectedItem, designer)}
            onClickSendToBack={updateZIndex("sendToBack", selectedItem, designer)}
            onClickBringForward={updateZIndex("bringForward", selectedItem, designer)}
            onClickSendBackward={updateZIndex("sendBackward", selectedItem, designer)}
            disableBringForward={disableBringForward}
            disableSendBackward={disableSendBackward}
            showIcon={showIcon}
            isDisabled={disableBringForward && disableSendBackward}
        />
    );
}
LayersTool.displayName = "LayersTool";
