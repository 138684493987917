import React, { forwardRef } from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { ExpandingButton, AlignIcon, type ExpandingButtonHandles } from "@shared/features/StudioChrome";
import type { ArrangeButtonProps } from "./ArrangeButton";
import { alignmentMessages } from "./alignmentMessages";

/**
 * This button is responsible for toggling bold on text on and off
 */
export const TextAlignmentButton = forwardRef<ExpandingButtonHandles, ArrangeButtonProps>(
    (props: ArrangeButtonProps, ref) => {
        const { t } = useTranslationSSR();
        const { className, onOpen, showIcon, content, isDisabled = false } = props;

        return (
            <ExpandingButton
                className={className}
                content={content}
                onOpen={onOpen}
                isDisabled={isDisabled}
                showArrow={false}
                displayType="singlerow"
                ref={ref}
            >
                {showIcon && <AlignIcon aria-hidden={true} />}
                <div className="dcl-ctx-button-label">{t(alignmentMessages.align.id)}</div>
            </ExpandingButton>
        );
    }
);

TextAlignmentButton.displayName = "TextAlignmentButton";
