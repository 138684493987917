import React from "react";
import {
    RotateTool,
    CropTool,
    DuplicateTool,
    FillColorPicker,
    TextFontSizeSelector,
    TableFontSizeSelector,
    LockTool,
    SharpenTool,
    StrikethroughButton,
    StrokeColorPicker,
    StrokeWidthSelector,
    TextAlignGroup,
    UnderlineButton,
    TableDesignSelectorStudio5,
    LayersTool,
    DeleteTool,
    KnockoutButton,
    PremiumFinishButton
} from "@designer-suite";
import { EditQRCodeTool } from "@five/components/QRCodes/EditQRCodeTool";
import { ReplacePlaceholderQRCodeTool } from "@five/components/QRCodes/ReplacePlaceholderQRCodeTool";
import { ImageChangeColorButton } from "./Buttons/ImageChangeColorButton";
import { WordArtAndTextBoldButton } from "./Buttons/WordArtAndTextBoldButton";
import { WordArtAndTextItalicButton } from "./Buttons/WordArtAndTextItalicButton";
import { WordArtAndTextFontFamilyButton } from "./Buttons/WordArtAndTextFontFamilyButton";
import { WordArtAndTextColorPicker } from "./Buttons/WordArtAndTextColorPicker";
import { CurveToolbarButton } from "./CurveTool/CurveToolbarButton";
import { TableBoldButton } from "./Buttons/TableBoldButton";
import { TableItalicButton } from "./Buttons/TableItalicButton";
import { TableFontColorPicker } from "./Buttons/TableFontColorPicker";
import { TableBackgroundColorPicker } from "./Buttons/TableBackgroundColorPicker";
import { TableFontFamilyButton } from "./Buttons/TableFontFamilyButton";
import { TableTextAlignGroup } from "./Alignment/TableTextAlignGroup";
import { ImageRecolorizationTool } from "./Buttons/ImageRecolorizationTool";
import { RemoveTableRowTool } from "./Buttons/RemoveTableRowTool";
import { RemoveTableColumnTool } from "./Buttons/RemoveTableColumnTool";
import { AddTableRowTool } from "./Buttons/AddTableRowTool";
import { AddTableColumnTool } from "./Buttons/AddTableColumnTool";
import { TableColumnAlignTool } from "./Alignment/TableColumnAlignTool";
import { ReplaceImageTool } from "./ReplaceImages/ReplaceImageTool";
import WordArtFontSizeButtonContainer from "./WordArtFontSize/WordArtFontSizeButtonContainer";
import TeamsFontSizeButtonContainer from "./TeamsFontSize/TeamsFontSizeButtonContainer";
import { TextListGroup } from "./TextLists/TextListGroup";
import { ToolgroupTypes } from "./ToolbarGroupsConfig";
import { EditShapeTool } from "./Shapes/EditShapeTool";
import { PremiumFinishMaskButton } from "./Buttons/PremiumFinishMaskButton";
import { TeamsFontFamilyButton } from "./Buttons/TeamsFontFamilyButton";
import { TeamsFontColorPicker } from "./Buttons/TeamsFontColorPicker";
import { EditImageButton } from "../../../studioFive/xerox/features/EditImage/EditImageButton";

export const generateContextualToolbarFloatingTools = (toolgroupType: ToolgroupTypes, isXerox: boolean) => {
    switch (toolgroupType) {
        case ToolgroupTypes.WordArtAndTextToolbar:
            return {
                WordArtAndTextFontFamilyButton: <WordArtAndTextFontFamilyButton />,
                WordArtFontSizeButtonContainer: <WordArtFontSizeButtonContainer />,
                FontSizeSelector: <TextFontSizeSelector />,
                WordArtAndTextColorPicker: <WordArtAndTextColorPicker />,
                WordArtAndTextBoldButton: <WordArtAndTextBoldButton />,
                WordArtAndTextItalicButton: <WordArtAndTextItalicButton />,
                UnderlineButton: <UnderlineButton />,
                StrikethroughButton: <StrikethroughButton />,
                PremiumFinishButton: <PremiumFinishButton showIcon />,
                RotateAngleSelector: <RotateTool showIcon />,
                DeleteButton: <DeleteTool showIcon />,
                CurveToolbarButton: <CurveToolbarButton showIcon />,
                TextListGroup: <TextListGroup showIcon />,
                TextAlignGroup: <TextAlignGroup showIcon />,
                LayersTool: <LayersTool showIcon />,
                LockButton: <LockTool showIcon />,
                DuplicateButton: <DuplicateTool showIcon />
            };

        case ToolgroupTypes.ImageToolbar:
            return {
                EditImage: isXerox && <EditImageButton />,
                PremiumFinishMaskButton: <PremiumFinishMaskButton />,
                DeleteButton: <DeleteTool showIcon />,
                ImageChangeColorButton: <ImageChangeColorButton showIcon />,
                CropTool: <CropTool showIcon />,
                PremiumFinishButton: <PremiumFinishButton showIcon />,
                RotateAngleSelector: <RotateTool />,
                ImageRecolorizationTool: !isXerox && <ImageRecolorizationTool displayType="both" />,
                ReplaceImageButton: <ReplaceImageTool showIcon />,
                KnockoutButton: !isXerox && <KnockoutButton showIcon />,
                LayersTool: <LayersTool showIcon />,
                SharpenTool: !isXerox && <SharpenTool alwaysShow showIcon />,
                DuplicateButton: <DuplicateTool showIcon />,
                LockButton: <LockTool showIcon />
            };

        case ToolgroupTypes.ImagePlaceholderToolbar:
            return {
                DeleteButton: <DeleteTool showIcon />,
                ReplaceImageButton: <ReplaceImageTool showIcon />,
                ReplaceQRCodePlaceholderButton: <ReplacePlaceholderQRCodeTool showIcon />,
                LockButton: <LockTool showIcon />
            };

        case ToolgroupTypes.ShapeToolbar:
            return {
                FillColorPicker: <FillColorPicker />,
                StrokeColorPicker: <StrokeColorPicker />,
                StrokeWidthSelector: <StrokeWidthSelector className="stroke-width-selector" />,
                EditShapeExpandingButton: <EditShapeTool showIcon />,
                LayersTool: <LayersTool showIcon />,
                LockButton: <LockTool showIcon />,
                DeleteButton: <DeleteTool showIcon />,
                DuplicateButton: <DuplicateTool showIcon />,
                RotateAngleSelector: <RotateTool showIcon />
            };

        case ToolgroupTypes.TableToolbar:
            return {
                TableFontFamilyButton: <TableFontFamilyButton />,
                TableFontSizeSelector: <TableFontSizeSelector />,
                TableFontColorPicker: <TableFontColorPicker />,
                TableBackgroundColorPicker: <TableBackgroundColorPicker />,
                TableBoldButton: <TableBoldButton />,
                TableItalicButton: <TableItalicButton />,
                DeleteButton: <DeleteTool showIcon />,
                TableTextAlignGroup: <TableTextAlignGroup showIcon />,
                TableDesignSelector: <TableDesignSelectorStudio5 showIcon />,
                LayersTool: <LayersTool showIcon />,
                DuplicateButton: <DuplicateTool showIcon />,
                LockButton: <LockTool showIcon />,
                RotateAngleSelector: <RotateTool showIcon />
            };

        case ToolgroupTypes.TableSubselectionToolbar:
            return {
                AddTableRowAbove: <AddTableRowTool direction="above" showIcon />,
                AddTableRowBelow: <AddTableRowTool direction="below" showIcon />,
                AddTableColumnLeft: <AddTableColumnTool direction="left" showIcon />,
                AddTableColumnRight: <AddTableColumnTool direction="right" showIcon />,
                RemoveTableColumnButton: <RemoveTableColumnTool showIcon />,
                RemoveTableRowButton: <RemoveTableRowTool showIcon />,
                TableColumnAlign: <TableColumnAlignTool showIcon />
            };

        case ToolgroupTypes.QRCodeToolbar:
            return {
                DeleteButton: <DeleteTool showIcon />,
                EditQRCodeButton: <EditQRCodeTool showIcon />,
                LayersTool: <LayersTool showIcon />,
                DuplicateButton: <DuplicateTool showIcon />,
                LockButton: <LockTool showIcon />,
                RotateAngleSelector: <RotateTool showIcon />
            };

        case ToolgroupTypes.TeamsToolbar:
            return {
                TeamsFontFamilyButton: <TeamsFontFamilyButton />,
                TeamsFontSizeButtonContainer: <TeamsFontSizeButtonContainer />,
                TeamsFontColorPicker: <TeamsFontColorPicker />,
                RotateAngleSelector: <RotateTool showIcon />,
                DeleteButton: <DeleteTool showIcon />,
                LayersTool: <LayersTool showIcon />,
                DuplicateButton: <DuplicateTool showIcon />,
                LockButton: <LockTool showIcon />
            };

        default:
            return {
                LockButton: <LockTool showIcon />,
                DeleteButton: <DeleteTool showIcon />,
                DuplicateButton: <DuplicateTool showIcon />,
                RotateAngleSelector: <RotateTool showIcon />
            };
            break;
    }
};
