import React, { forwardRef } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ExpandingButton, ListBulletsIcon, type ExpandingButtonHandles } from "@shared/features/StudioChrome";
import type { ArrangeButtonProps } from "../ArrangeButton";

const messages = defineMessages({
    listTitle: {
        id: "easel.ui.text.list.title",
        defaultMessage: "List",
        description: {
            note: "Text in the contextual toolbar that opens tools for adding list to text"
        }
    }
});

/**
 * This button is responsible for toggling bulleted on numbers list styles on and off
 */
export const TextListButton = forwardRef<ExpandingButtonHandles, ArrangeButtonProps>(
    (props: ArrangeButtonProps, ref) => {
        const { t } = useTranslationSSR();
        const { className, onOpen, showIcon, content, isDisabled = false } = props;

        return (
            <ExpandingButton
                className={className}
                content={content}
                onOpen={onOpen}
                isDisabled={isDisabled}
                showArrow={false}
                displayType="singlerow"
                ref={ref}
            >
                {showIcon && <ListBulletsIcon aria-hidden={true} />}
                <div className="dcl-ctx-button-label">{t(messages.listTitle.id)}</div>
            </ExpandingButton>
        );
    }
);

TextListButton.displayName = "TextListButton";
