import React, { useRef } from "react";
import { TableDesignSelectorButton } from "@shared/features/ContextualToolbar";
import type { ExpandingButtonHandles } from "@shared/features/StudioChrome";
import { TableDesignSelector } from "@shared/features/Tables";
import { getTableStyleId, updateTableStyleId } from "@utilities";
import { updateSelectedItems } from "../util";
import { useDesigner } from "../designer/DesignerProvider";
import { useSelection } from "../designer/useSelection";
import { useTableSelectionFilter } from "../hooks/useTableSelectionFilter";

interface TableDesignSelectorStudio5Props {
    /** For overriding styles */
    className?: string;

    isDisabled?: boolean;

    closeOnSelection?: boolean;

    showIcon?: boolean;
}

export function TableDesignSelectorStudio5({
    className,
    isDisabled = false,
    closeOnSelection = false,
    showIcon = false
}: TableDesignSelectorStudio5Props) {
    const designer = useDesigner();
    const selection = useSelection("model:change:data");
    const { tableItems, otherItems } = useTableSelectionFilter(selection);
    const buttonRef = useRef<ExpandingButtonHandles>(null);

    if (!designer || tableItems.length !== 1 || otherItems.length) {
        return null;
    }

    const styleId = getTableStyleId(tableItems[0].data);
    if (!styleId) {
        return null;
    }

    const onChange = (styleId: number) => {
        updateSelectedItems(designer, tableItems, table => {
            updateTableStyleId(table, styleId);
        });

        if (buttonRef.current) {
            if (closeOnSelection) {
                buttonRef.current.close();
            }
        }
    };

    return (
        <TableDesignSelectorButton
            className={className}
            ref={buttonRef}
            content={<TableDesignSelector onChange={onChange} selectedId={styleId ?? 0} />}
            isDisabled={isDisabled}
            showIcon={showIcon}
        />
    );
}

TableDesignSelectorStudio5.displayName = "TableDesignSelectorStudio5";
