import React from "react";
import { Button } from "@shared/features/StudioChrome";
import { ColorIconType } from "@presentational";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { getIcon } from "../../../presentational/ColorPicker/getIcon";

const messages = defineMessages({
    tabelFontColorButtonAriaLabel: {
        id: "easel.components.tables.tabelFontColorButtonAriaLabel",
        defaultMessage: "Table font color",
        description: {
            note: "Label for a button that allows for changing table font color"
        }
    }
});

export function TableFontColorPicker() {
    const { t } = useTranslationSSR();
    const { setCurrentActiveDialog } = useActiveDialog();
    const colorIcon = getIcon(ColorIconType.TextColorIcon);

    return (
        <Button
            onClick={() => {
                setCurrentActiveDialog(DialogType.TableFontColorPicker);
            }}
            isSelected={false}
            isDisabled={false}
            className="colorpicker-button"
            aria-label={t(messages.tabelFontColorButtonAriaLabel.id)}
        >
            {colorIcon}
        </Button>
    );
}

TableFontColorPicker.displayName = "TableFontColorPicker";
