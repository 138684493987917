import React, { ReactNode } from "react";
import classnames from "classnames";
import { ExpandingButton, ImageRotateIcon } from "@shared/features/StudioChrome";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { State } from "@popperjs/core";

export interface RotateSelectionButtonProps {
    content: ReactNode | ((updatePopperPosition: () => Promise<Partial<State>>) => ReactNode);
    showIcon?: boolean;
    current?: number;
    className?: string;
    onOpen?: () => void;
    isDisabled?: boolean;
}

const messages = defineMessages({
    rotateButtonName: {
        id: "easel.presentational.Buttons.rotateButtonName",
        defaultMessage: "Rotate",
        description: {
            note: "Rotate button label"
        }
    }
});

export function RotateSelectionButton({
    content,
    className,
    onOpen,
    showIcon = true,
    isDisabled = false
}: RotateSelectionButtonProps) {
    const { t } = useTranslationSSR();

    return (
        <div className={classnames("easel-rotate-angle-button-container")}>
            <ExpandingButton
                content={content}
                displayType="singlerow"
                showArrow={false}
                className="rotate-expanding-button"
                buttonClassName={className}
                onOpen={onOpen}
                isDisabled={isDisabled}
            >
                {showIcon && <ImageRotateIcon aria-hidden={true} />}
                <div className="dcl-ctx-button-label swan-ml-2">{t(messages.rotateButtonName.id)}</div>
            </ExpandingButton>
        </div>
    );
}
RotateSelectionButton.displayName = RotateSelectionButton;
