import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { ExpandingButton, CurvedTextIcon } from "@shared/features/StudioChrome";
import { observer } from "mobx-react-lite";
import { CurvePanel } from "./CurvePanel";

const messages = defineMessages({
    curveToolTitleText: {
        id: "easel.ui.curveToolSheetContent.curveToolButtonTitle",
        defaultMessage: "Curve Text",
        description: {
            note: "Display at the header of the word art Curve sheet for mobile"
        }
    },
    curveToolTitle: {
        id: "easel.ui.curveToolbarButton.curveToolTitle",
        defaultMessage: "Curve Tool",
        description: {
            note: "The hover text for the curve button in the toolbar"
        }
    },
    curveToolText: {
        id: "easel.ui.curveToolbarButton.curveToolText",
        defaultMessage: "Curve Text",
        description: {
            note: "Display text for the curve button in the toolbar"
        }
    }
});

interface CurveToolbarButtonProps {
    className?: string;
    showIcon?: boolean;
    selectedCurve: CurveType;
    onChange: (selectedValue: string) => void;
}

export const CurveToolbarButton = observer(
    ({ className, showIcon = false, selectedCurve, onChange }: CurveToolbarButtonProps) => {
        const { t } = useTranslationSSR();

        return (
            <ExpandingButton
                className={className}
                content={<CurvePanel selectedCurve={selectedCurve} onChange={onChange} />}
                showArrow={false}
                displayType="singlerow"
            >
                {showIcon && <CurvedTextIcon />}
                <div className="dcl-ctx-button-label">{`${t(messages.curveToolText.id)}`}</div>
            </ExpandingButton>
        );
    }
);
CurveToolbarButton.displayName = "CurveToolbarButton";
