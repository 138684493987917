import React from "react";
import { Button } from "@shared/features/StudioChrome";
import type { SimpleButtonProps } from "./types";

interface IconButtonProps extends SimpleButtonProps {
    ariaLabel: string;
    icon: JSX.Element;
}

export function IconButton({ ariaLabel, icon, onClick, className, disabled, ...rest }: IconButtonProps) {
    return (
        <Button onClick={onClick} className={className} aria-label={ariaLabel} isDisabled={disabled} {...rest}>
            {icon}
        </Button>
    );
}
IconButton.displayName = "IconButton";
