import React from "react";
import { textFilter, useDecorationTechnology } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useTrackEvents } from "@shared/features/Tracking";
import { TextListButton } from "@shared/features/ContextualToolbar";
import { DecorationTechnologiesSimple } from "@shared/utils/CimDoc";
import { useDesigner } from "../../designer/DesignerProvider";
import { useSelection } from "../../designer/useSelection";
import { TextListOptionGroupStudio5 } from "./TextListOptionGroupStudio5";

interface Props {
    /** For overriding styles */
    className?: string;
    showIcon?: boolean;
}

/**
 * Connected List Tool
 */
export function TextListGroup({ className = "expanding-tool-icon", showIcon }: Props) {
    const designer = useDesigner();
    const selection = useSelection("model:change:content item:focus");
    const { matchingItems, otherItems } = textFilter(designer, selection);
    const decorationTechnology = useDecorationTechnology();
    const { trackEvent } = useTrackEvents();

    if (
        !designer ||
        decorationTechnology === DecorationTechnologiesSimple.EMBROIDERY ||
        matchingItems.length === 0 ||
        otherItems.length > 0
    ) {
        return null;
    }

    return (
        <TextListButton
            showIcon={showIcon}
            className={className}
            onOpen={() => {
                trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_LIST });
            }}
            content={<TextListOptionGroupStudio5 />}
        />
    );
}
TextListGroup.displayName = "TextListGroup";
