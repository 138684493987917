export interface ToolgroupConfig {
    key: string;
    toolRef: React.RefObject<HTMLDivElement>;
    component: JSX.Element;
}

export interface ToolbarGroupsConfig {
    items?: ToolgroupConfig[];
    visible: ToolgroupConfig[];
    hidden: ToolgroupConfig[];
    showMore: boolean;
}

export enum ToolgroupTypes {
    WordArtAndTextToolbar = "WordArtAndTextToolbar",
    ShapeToolbar = "ShapeToolbar",
    ImageToolbar = "ImageToolbar",
    ImagePlaceholderToolbar = "ImagePlaceholderToolbar",
    TableToolbar = "TableToolbar",
    TableSubselectionToolbar = "TableSubselectionToolbar",
    QRCodeToolbar = "QRCodeToolbar",
    TeamsToolbar = "TeamsToolbar",
    DefaultToolbar = "DefaultToolbar"
}
