import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { useDesigner, useSelection } from "@designer-suite";
import { updateSelectedItems, updateTableAlignment, getTrackingDataForSelection } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { ExpandingButton, AlignIcon } from "@shared/features/StudioChrome";
import { alignmentMessages } from "@shared/features/ContextualToolbar";
import { TextAlignOptionGroup } from "./TextAlignOptionGroup";
import { useTableSelectionFilter } from "../../hooks/useTableSelectionFilter";
import type { Designer } from "../../@types/designer";

/**
 * This function gets the common alignment for all table rows in the group of tables
 * If any of the table rows have differing alignments, an empty string will indicate that there is not a common alignment.
 * @param {Designer} designer designer instance
 * @param {TableItem[]} tableItems array of tables that we want to get the overall alignment for
 */
function getHorizontalAlignment(designer: Designer, tableItems: TableItem[]) {
    const selectedAlignments = tableItems.reduce<Alignment[]>((acc, curr) => {
        const results = [...acc];
        curr.data.columns.forEach(column => {
            if (!results.includes(column.alignment)) {
                results.push(column.alignment);
            }
        });
        return results;
    }, []);
    return selectedAlignments.length === 1 ? selectedAlignments[0] : "";
}

const listenEvents = "model:change:data";

interface Props {
    /** For overriding styles */
    className?: string;
    isMobile?: boolean;
    showIcon?: boolean;
}

/**
 * The button group that enables changing horizontal alignment
 */
export function TableTextAlignGroup({ className = "expanding-tool-icon", isMobile, showIcon }: Props) {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const selection = useSelection(listenEvents);
    const { tableItems, otherItems } = useTableSelectionFilter(selection);
    const onChange = React.useCallback(
        alignment => updateSelectedItems(designer, selection, updateTableAlignment(alignment)),
        [designer, selection]
    );
    // We only want to do this for tables only
    if (!designer || !tableItems.length || otherItems.length) {
        return null;
    }

    const selectedAlignment = getHorizontalAlignment(designer, tableItems);

    return (
        <ExpandingButton
            className={className}
            content={<TextAlignOptionGroup className={className} alignment={selectedAlignment} onChange={onChange} />}
            onOpen={() => {
                designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_ALIGN, getTrackingDataForSelection(selection));
            }}
            isDisabled={false}
            showArrow={false}
            displayType="singlerow"
        >
            {showIcon && <AlignIcon aria-hidden={true} />}
            <div className="dcl-ctx-button-label">{t(alignmentMessages.align.id)}</div>
        </ExpandingButton>
    );
}
TableTextAlignGroup.displayName = "TableTextAlignGroup";
