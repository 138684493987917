import React, { forwardRef, useEffect } from "react";
import classNames from "classnames";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ExpandingButton, FontFamilyIcon, type ExpandingButtonHandles } from "@shared/features/StudioChrome";
import { useAppSelector } from "@shared/redux";
import type { ArrangeButtonProps } from "./ArrangeButton";
import * as styles from "./FontFamilyButton.module.scss";

const messages = defineMessages({
    buttonTitle: {
        id: "easel.designerSuite.fontFamilyButton.title",
        defaultMessage: "Font Selector",
        description: {}
    },
    emptyStyleLabel: {
        id: "easel.designerSuite.fontFamilyButton.emptyStyleLabel",
        defaultMessage: "Choose a style..",
        description: {}
    }
});

interface FontFamilyButtonProps extends ArrangeButtonProps {
    selectedFontFamily?: string;
}

export const FontFamilyButton = forwardRef<ExpandingButtonHandles, FontFamilyButtonProps>(
    (props, ref: React.RefObject<ExpandingButtonHandles>) => {
        const { content, onOpen, className, onClose, showArrow, selectedFontFamily, ...rest } = props;
        const { t } = useTranslationSSR();

        const fontBrowserOpened = useAppSelector(state => state.showFontBrowser);

        useEffect(() => {
            if (fontBrowserOpened) {
                ref?.current?.close?.();
            }
        }, [fontBrowserOpened, ref]);

        return (
            <ExpandingButton
                ref={ref}
                onOpen={onOpen}
                onClose={onClose}
                className={classNames(styles.fontFamilyButton, className)}
                title={t(messages.buttonTitle.id)}
                content={content}
                showArrow={showArrow}
                displayType="multirow"
                {...rest}
                data-testid="font-family-button"
            >
                <FontFamilyIcon aria-hidden={true} />
                <div className="dcl-ctx-button-label fontfamilyexpandingbutton-selectedfont">
                    {selectedFontFamily || t(messages.emptyStyleLabel.id)}
                </div>
            </ExpandingButton>
        );
    }
);

FontFamilyButton.displayName = "FontFamilyButton";
