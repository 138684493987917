import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ToolbarButton } from "@shared/features/StudioChrome";
import classNames from "classnames";
import * as styles from "./MoreButton.module.scss";

const messages = defineMessages({
    moreButtonTitle: {
        id: "easel.ui.moreButton.moreButtonTitle",
        defaultMessage: "More Tools",
        description: {
            note: "The hover text for the more button in the toolbar"
        }
    },
    moreButtonText: {
        id: "easel.ui.moreButton.moreButtonText",
        defaultMessage: "More",
        description: {
            note: "The displayed text for the more button in the toolbar"
        }
    }
});

interface MoreButtonProps extends Omit<React.ComponentProps<typeof ToolbarButton>, "onClick"> {
    onClick?: () => void;
}

export const MoreButton = (props: MoreButtonProps) => {
    const { onClick, className, ...rest } = props;
    const { t } = useTranslationSSR();

    return (
        <ToolbarButton
            onClick={onClick}
            title={t(messages.moreButtonText.id)}
            className={classNames(styles.moreButton, "easel-toolbar-more-button tool-icon", className)}
            {...rest}
        >
            <div className="dcl-ctx-button-label">{t(messages.moreButtonText.id)}</div>
            <span className={styles.icon}></span>
        </ToolbarButton>
    );
};

MoreButton.displayName = "MoreButton";
