import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ExpandingButton, PremiumFinishIcon } from "@shared/features/StudioChrome";
import classNames from "classnames";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { useFinishType, useHasFinish } from "@utilities";
import { useTrackEvents } from "@shared/features/Tracking";
import { useActiveCanvas, useDesigner, useSelection } from "@designer-suite";
import { useAppSelector } from "@shared/redux";
import { FinishMessages, SUPPORTED_FINISHES } from "@shared/features/PremiumFinish";
import { PremiumFinishToolbarContent } from "../../../studioFive/components/Redesign/PremiumFinish/PremiumFinishToolbarContent";
import "./premiumFinishButton.scss";

type Props = {
    showIcon?: boolean;
};

const messages = defineMessages({
    premiumFinishButtonTitle: {
        id: "easel.ui.button.premiumFinishButton.title",
        defaultMessage: "Finishes",
        description: {
            note: "Finishes button in formatting bar"
        }
    }
});

export function PremiumFinishButton({ showIcon }: Props) {
    const { t } = useTranslationSSR();
    const { trackEvent } = useTrackEvents();
    const designer = useDesigner();
    const selection = useSelection(
        "model:change:overprints model:change:premiumFinish model:change:selected model:change:data model:change:content item:focus item:addText"
    );
    const canvas = useActiveCanvas();
    const hasFinish = useHasFinish(selection);
    const isFullBleed = useAppSelector(state => state.isFullBleed);
    const finishType = useFinishType();

    if (!designer || !selection.length || !canvas) {
        return null;
    }

    if (!(designer && finishType && SUPPORTED_FINISHES.includes(finishType) && !isFullBleed)) {
        return null;
    }

    return (
        <ExpandingButton
            className="premium-finish-button-toolbar"
            onOpen={() => {
                trackEvent({ eventDetail: STUDIO_TRACKING_EVENTS.CLICK_PREMIUM_FINISH });
            }}
            title={t(FinishMessages[finishType].id)}
            content={<PremiumFinishToolbarContent items={selection} />}
            showArrow={false}
            displayType="singlerow"
        >
            {showIcon && <PremiumFinishIcon className={classNames({ "has-finish": hasFinish })} />}
            <div className="dcl-ctx-button-label">{t(messages.premiumFinishButtonTitle.id)}</div>
        </ExpandingButton>
    );
}

PremiumFinishButton.displayName = "PremiumFinishButton";
