import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDesigner, useFontSelectorFontListStudio5, useSelection, type Font } from "@designer-suite";
import { getTrackingDataForSelection } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import type { ExpandingButtonHandles } from "@shared/features/StudioChrome";
import { FontFamilyButton, FontSelectorPanel } from "@shared/features/ContextualToolbar";
import { keyboardShortcutEvents, useKeyboardShortcuts } from "@shared/features/KeyboardShortcuts";
import { useRecentFontsContext } from "./RecentFontsProvider";
import { useCreateRenderFontResultForStudio5 } from "./useCreateRenderFontResultForStudio5";
import { useEnableFontBrowser } from "./useEnableFontBrowser";

function useFontFamilyKeyboardBinding({ isDropdownOpen }: { isDropdownOpen: boolean }) {
    const ref = useRef<ExpandingButtonHandles>(null);
    const { on, off } = useKeyboardShortcuts();
    useEffect(() => {
        const toggleFontBrowser = () => {
            if (isDropdownOpen) {
                ref?.current?.close();
            } else {
                ref?.current?.open();
            }
        };

        on(keyboardShortcutEvents.toggleFontBrowser, toggleFontBrowser);

        return () => {
            off(keyboardShortcutEvents.toggleFontBrowser, toggleFontBrowser);
        };
    }, [on, off, isDropdownOpen]);

    return ref;
}

interface Props {
    /** The option value from a user selection or default value, can also be empty. */
    selectedFontFamily?: string;
    /** Options list with user selectable options. */
    fonts: Font[];
    /** Will return the new selected option value. */
    onSelectFont(fontFamily: string, inRecentFonts: boolean): void;
    /** For overriding styles */
    className?: string;
    /** When set to false, the chevron is hidden */
    showArrow?: boolean;
}

/**
 * This button is responsible for opening the font family tool
 */
export function FontFamilyButtonStudio5({
    selectedFontFamily,
    fonts,
    className,
    onSelectFont,
    showArrow = true
}: Props) {
    const designer = useDesigner();
    const selection = useSelection();
    const enableFontBrowser = useEnableFontBrowser();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { lastSelectedFont, updateRecentFonts, recentFonts, updateLastSelectedFont } = useRecentFontsContext();

    const ref = useFontFamilyKeyboardBinding({ isDropdownOpen });

    const handleOpen = useCallback(() => {
        designer &&
            designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_FONT, getTrackingDataForSelection(selection));
        setIsDropdownOpen(true);
    }, [designer, selection]);

    const handleClose = useCallback(() => {
        updateRecentFonts(lastSelectedFont);
        setIsDropdownOpen(false);
    }, [lastSelectedFont, updateRecentFonts]);

    const combinedFontList = useFontSelectorFontListStudio5({
        fontList: fonts,
        selectedFontFamily,
        useRecentFonts: useRecentFontsContext
    });

    const renderFontResult = useCreateRenderFontResultForStudio5(combinedFontList);

    return (
        <FontFamilyButton
            ref={ref}
            onOpen={handleOpen}
            onClose={handleClose}
            className={className}
            content={
                <FontSelectorPanel
                    onOpenFontBrowser={() => {
                        designer && designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_FONT_BROWSER);
                    }}
                    enableFontBrowser={enableFontBrowser}
                    selectedFontFamily={selectedFontFamily}
                    allFonts={combinedFontList.map(font => font.family)}
                    recentFonts={recentFonts}
                    onSelectFont={onSelectFont}
                    updateLastSelectedFont={updateLastSelectedFont}
                    renderFontResult={renderFontResult}
                />
            }
            showArrow={showArrow}
            selectedFontFamily={selectedFontFamily}
            data-testid="font-family-button"
        />
    );
}
FontFamilyButtonStudio5.displayName = "FontFamilyButtonStudio5";
