import React, { useCallback } from "react";
import { ColorIconType } from "@presentational";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { useDesigner, useSelection } from "@designer-suite";
import { IconButton } from "@shared/features/ContextualToolbar";
import { DialogType, useActiveDialog } from "@shared/features/ActiveDialog";
import { getIsStrokeColorCompatible } from "./colorUtil";
import { getIcon } from "../../presentational/ColorPicker/getIcon";

const KEY = "strokeColor";

const messages = defineMessages({
    strokeColorPicker: {
        id: "easel.ui.wordArtAndTextColorPicker.fillColorPicker",
        defaultMessage: "Stroke Color",
        description: {
            note: "Label for a button that allows for changing Stroke colors"
        }
    }
});

const getListenEvents = (key: string) => `model:change:${key}`;
const listenEvents = getListenEvents(KEY);

/**
 * Responsible for changing the stroke color on shapes
 */

export function StrokeColorPicker() {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const selection = useSelection(listenEvents);
    const colorIcon = getIcon(ColorIconType.StrokeColorIcon);
    const canPickColor = getIsStrokeColorCompatible(designer, selection);
    const { setCurrentActiveDialog } = useActiveDialog();
    const onClick = useCallback(() => {
        setCurrentActiveDialog(DialogType.StrokeColorPicker);
    }, [setCurrentActiveDialog]);

    if (!canPickColor) {
        return null;
    }

    return (
        <IconButton
            ariaLabel={t(messages.strokeColorPicker.id)}
            icon={colorIcon}
            onClick={onClick}
            className={"colorpicker-button"}
        />
    );
}
StrokeColorPicker.displayName = "StrokeColorPicker";
