import React from "react";
import { FontFamilyButtonStudio5 } from "@presentational";
import { useDesigner, useFonts } from "@designer-suite";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import {
    updateSelectedItems,
    getTableSelectedFont,
    updateTableFontFamily,
    getTrackingDataForSelection
} from "@utilities";
import { useSelection } from "../../designer/useSelection";
import { useTableSelectionFilter } from "../../hooks/useTableSelectionFilter";

type Props = {
    showArrow?: boolean;
    className?: string;
};

const listenEvents = "model:change:data";

/**
 * Responsible for changing fonts
 */
export function TableFontFamilyButton(props: Props) {
    const { showArrow = true, className = "expanding-tool-icon" } = props;
    const designer = useDesigner();
    const selection = useSelection(listenEvents);
    const fonts = useFonts();
    const { tableItems, otherItems } = useTableSelectionFilter(selection);

    if (!designer || !tableItems.length || otherItems.length) {
        return null;
    }

    const selectedFontFamily = getTableSelectedFont(tableItems);

    function onSelectFont(fontFamily: string, inRecentFonts: boolean) {
        designer?.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_FONT_CHOICE, {
            ...getTrackingDataForSelection(selection),
            fontFamily,
            inRecentFonts
        });
        updateSelectedItems(designer, tableItems, table => {
            updateTableFontFamily(table, fontFamily);
        });
    }

    return (
        <FontFamilyButtonStudio5
            onSelectFont={onSelectFont}
            selectedFontFamily={selectedFontFamily}
            fonts={fonts}
            showArrow={showArrow}
            className={className}
        />
    );
}
TableFontFamilyButton.displayName = "TableFontFamilyButton";
