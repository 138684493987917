import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { EditShapeIcon, ExpandingButton } from "@shared/features/StudioChrome";
import type { ArrangeButtonProps } from "./ArrangeButton";

const messages = defineMessages({
    label: {
        id: "easel.ui.buttons.editShape.label",
        defaultMessage: "Edit Shape",
        description: {
            note: "Label for the edit shape popout button"
        }
    }
});

export const EditShapeExpandingButton = (props: ArrangeButtonProps) => {
    const { className, showIcon = false, content, onOpen, buttonClassName, isDisabled } = props;
    const { t } = useTranslationSSR();

    return (
        <ExpandingButton
            className={className}
            buttonClassName={buttonClassName}
            showArrow={false}
            displayType="singlerow"
            onOpen={onOpen}
            content={content}
            isDisabled={isDisabled}
        >
            {showIcon && <EditShapeIcon />}
            <div className="dcl-ctx-button-label">{t(messages.label.id)}</div>
        </ExpandingButton>
    );
};

EditShapeExpandingButton.displayName = "EditShapeExpandingButton";
