import React from "react";
import { FontFamilyButtonStudio5 } from "@presentational";
import { useDesigner, useSelection, useFonts, useRichTextAttribute } from "@designer-suite";
import { wordArtFilter, getTrackingDataForSelection, selectedItemsAreOfTypes, isWordArt } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { useOnFontSelect, onSelectWordArt } from "../../dropdowns/onFontSelect";
import type { Designer, ItemSelection } from "../../@types/designer";

function getIsCompatible(designer: Designer | undefined, selection: ItemSelection): designer is Designer {
    return designer !== undefined && selectedItemsAreOfTypes(selection, [ItemTypes.TEXT]);
}

function getSelectedFont(selection: ItemSelection) {
    const selectedFontFamilies = selection.reduce((accumulator: any, current: WordArtItem | TextItem) => {
        if (isWordArt(current)) {
            const currentWordArtItem = current as WordArtItem;
            if (accumulator.includes(currentWordArtItem.data.fontFamily)) {
                return accumulator;
            }
            return [...accumulator, currentWordArtItem.data.fontFamily];
        }
        const currentTextItem = current as TextItem;
        if (accumulator.includes(currentTextItem.font)) {
            return accumulator;
        }
        return [...accumulator, currentTextItem.font];
    }, []);
    return selectedFontFamilies.length === 1 ? selectedFontFamilies[0] : "";
}

/**
 * Responsible for changing fonts
 */
export function WordArtAndTextFontFamilyButton({ showArrow = true, className = "expanding-tool-icon" }) {
    const designer = useDesigner();
    const fonts = useFonts();
    const onSelect = useOnFontSelect();
    const events = `model:change:data model:change:content item:focus`;
    const selection = useSelection(events);
    const { wordArtItems, otherItems } = wordArtFilter(designer, selection);
    const selectedRichTextFontFamily = useRichTextAttribute<string>("common", "fontFamily", "");

    if (!selection.length || (!getIsCompatible(designer, selection) && !wordArtItems.length)) {
        return null;
    }

    if (!designer || (!wordArtItems.length && !otherItems.length) || !getIsCompatible(designer, otherItems)) {
        return null;
    }

    const selectedFontFamily = getSelectedFont([...wordArtItems, ...otherItems]);

    function onSelectFont(fontFamily: string, inRecentFonts: boolean) {
        designer &&
            designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_FONT_CHOICE, {
                ...getTrackingDataForSelection(selection),
                fontFamily,
                inRecentFonts
            });
        onSelect(otherItems, fontFamily);
        onSelectWordArt(designer, wordArtItems, fontFamily);
    }

    return (
        <FontFamilyButtonStudio5
            onSelectFont={onSelectFont}
            selectedFontFamily={selectedFontFamily || selectedRichTextFontFamily}
            fonts={fonts}
            showArrow={showArrow}
            className={className}
        />
    );
}
WordArtAndTextFontFamilyButton.displayName = "WordArtAndTextFontFamilyButton";
