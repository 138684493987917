import React from "react";
import { useTranslationSSR } from "@vp/i18n-helper";
import { Button } from "@shared/features/StudioChrome";
import { ColorIconType } from "@presentational";
import { useDesigner, useSelection, useActiveCanvas } from "@designer-suite";
import { teamsPlaceholderFilter } from "@utilities";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { getIcon } from "../../../presentational/ColorPicker/getIcon";
import {
    colorPickerMessages,
    getIsTextColorPickerCompatible,
    shouldTextColorPickerBeDisabled
} from "./WordArtAndTextColorPicker";

export function TeamsFontColorPicker() {
    const designer = useDesigner();
    const { t } = useTranslationSSR();
    const selection = useSelection(`model:change:data`);
    const activeCanvas = useActiveCanvas();
    const { teamsPlaceholderItems, otherItems } = teamsPlaceholderFilter(selection);
    const { setCurrentActiveDialog } = useActiveDialog();

    if (
        !designer ||
        !teamsPlaceholderItems.length ||
        otherItems.length ||
        !getIsTextColorPickerCompatible(designer, otherItems)
    ) {
        return null;
    }

    const colorIcon = getIcon(ColorIconType.TextColorIcon);

    return (
        <Button
            onClick={() => {
                setCurrentActiveDialog(DialogType.TeamsFontColorPicker);
            }}
            isSelected={false}
            isDisabled={shouldTextColorPickerBeDisabled(selection, activeCanvas)}
            className="colorpicker-button"
            title={t(colorPickerMessages.textColorPicker.id)}
            aria-label={t(colorPickerMessages.textColorPicker.id)}
        >
            {colorIcon}
        </Button>
    );
}

TeamsFontColorPicker.displayName = "TeamsFontColorPicker";
