/* eslint-disable max-len */
import React from "react";

interface Props {
    props?: React.SVGProps<SVGSVGElement>;
}

export function PremiumFinishMaskIcon(props: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.21181 0C10.0209 6.00516 13.0438 9.10885 18.3889 9.34195C13.0448 9.57603 10.0207 12.7114 9.21181 18.6839C8.40274 12.7114 5.34409 9.57505 0 9.34195C5.34409 9.10788 8.40259 6.00516 9.21181 0Z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.9532 11.7557C18.481 15.6588 20.4852 17.7282 24.0001 17.8615C20.4838 18.0289 18.4798 20.0642 17.9532 24C17.4253 20.0628 15.4211 18.0275 11.9407 17.8615C15.421 17.7281 17.4253 15.6589 17.9532 11.7557Z"
            />
            <rect width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" />
        </svg>
    );
}
PremiumFinishMaskIcon.displayName = "PremiumFinishMaskIcon";
