import React, { ReactNode } from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { PremiumFinishMaskIcon, Button } from "@shared/features/StudioChrome";

const messages = defineMessages({
    premiumFinishMaskButton: {
        id: "easel.components.premiumFinishMaskButton",
        defaultMessage: "Mask",
        description: {
            note: "Opens Premium Finish Mask Dialog"
        }
    }
});
interface Props {
    /** Click handler
     */
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    children?: ReactNode | ReactNode[];
    /** For overriding styles
     */
    className?: string;
    /** Whether or not the button is disabled */
    isDisabled?: boolean;
    iconOnly?: boolean;
}
export function PremiumFinishMaskButton({ onClick, className, children, iconOnly }: Props) {
    const { t } = useTranslationSSR();

    return (
        <Button title={t(messages.premiumFinishMaskButton.id)} className={className} onClick={onClick}>
            <PremiumFinishMaskIcon />
            {!iconOnly && <div className="dcl-ctx-button-label">{t(messages.premiumFinishMaskButton.id)}</div>}
            {children}
        </Button>
    );
}

PremiumFinishMaskButton.displayName = "PremiumFinishMaskButton";
