import React from "react";
import { Box } from "@vp/swan";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { getItemStudioMetadataProperty } from "@utilities";
import { useTrackEvents } from "@shared/features/Tracking";
import { EditShapeExpandingButton } from "@shared/features/ContextualToolbar";
import { StudioFiveEditShapeContainer } from "./StudioFiveEditShapeContainer";
import { useDesigner } from "../../designer/DesignerProvider";
import { useSelection } from "../../designer/useSelection";

interface Props {
    /** For overriding styles */
    className?: string;
    showIcon: boolean;
}

export function EditShapeTool({ className = "expanding-tool-icon", showIcon = true }: Props) {
    const designer = useDesigner();
    const selection = useSelection();
    const { trackEvent } = useTrackEvents();

    if (!designer || selection.length !== 1) {
        return null;
    }

    const shape = selection[0];
    if (shape.itemType !== ItemTypes.SHAPE) {
        return null;
    }

    const metadata = getItemStudioMetadataProperty(shape, "shapeMetadata");
    if (!metadata) {
        return null;
    }

    return (
        <EditShapeExpandingButton
            className={className}
            showIcon={showIcon}
            content={
                <Box>
                    <StudioFiveEditShapeContainer shape={shape as ShapeItem} />
                </Box>
            }
            onOpen={() => {
                trackEvent({
                    eventDetail: STUDIO_TRACKING_EVENTS.EDIT_SHAPE_SELECTION,
                    label: "Shapes"
                });
            }}
        />
    );
}
EditShapeTool.displayName = "EditShapeTool";
