import React, { useState } from "react";
import loadable from "@loadable/component";
import { Button } from "@shared/features/StudioChrome";
import { ToolGroup } from "@presentational";
import { useSelection } from "@designer-suite";
import { loadableRetry } from "@shared/utils/Network";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { SelectionFilter } from "../SelectionFilterComponent";
import type { ItemDebugPanelProps } from "../ItemDebug/ItemDebugPanel";

const ItemDebugPanel = loadable<ItemDebugPanelProps, any>(
    () => loadableRetry(() => import("../ItemDebug/ItemDebugPanel")),
    {
        resolveComponent: components => components.ItemDebugPanel
    }
);

const messages = defineMessages({
    debugItembuttonLabel: {
        id: "easel.ui.debugItem.debugItembuttonLabel",
        defaultMessage: "Debug Item",
        description: {
            note: "Label and title for a Debug Item button"
        }
    }
});
export function ItemDebugButton() {
    const selection = useSelection("model:change");
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslationSSR();

    const handleModalOpen = () => {
        setShowModal(true);
    };
    const handleModalClose = () => {
        setShowModal(false);
    };

    const onButtonClick = () => {
        handleModalOpen();
    };

    return (
        <SelectionFilter predicate={() => selection.length === 1}>
            <ItemDebugPanel selection={selection} showModal={showModal} closeModal={handleModalClose} />
            <ToolGroup>
                <Button onClick={onButtonClick} className="easel-debug-button">
                    <div className="dcl-ctx-button-label" style={{ whiteSpace: "nowrap" }}>
                        {t(messages.debugItembuttonLabel.id)}
                    </div>
                </Button>
            </ToolGroup>
        </SelectionFilter>
    );
}

ItemDebugButton.displayName = "ItemDebugButton";
