import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ToolbarButton } from "@shared/features/StudioChrome";

import classNames from "classnames";
import * as styles from "./BackButton.module.scss";

const messages = defineMessages({
    backButtonTitle: {
        id: "easel.ui.backButton.backButtonTitle",
        defaultMessage: "Back",
        description: {
            note: "The hover text for the back button in the toolbar"
        }
    }
});

interface BackButtonProps extends Omit<React.ComponentProps<typeof ToolbarButton>, "onClick"> {
    onClick: () => void;
}

export function BackButton(props: BackButtonProps) {
    const { onClick, className, ...rest } = props;
    const { t } = useTranslationSSR();

    return (
        <ToolbarButton
            onClick={onClick}
            className={classNames(
                styles.backButton,
                "easel-toolbar-back-button easel-button-theme-override tool-icon",
                className
            )}
            {...rest}
        >
            <span className={classNames(styles.icon, "easel-toolbar-arrow")} />
            <div className="dcl-ctx-button-label">{t(messages.backButtonTitle.id)}</div>
        </ToolbarButton>
    );
}

BackButton.displayName = "BackButton";
