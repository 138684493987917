import React from "react";
import { CurveToolbarButton as CurveToolbarButtonPresentational } from "@shared/features/ContextualToolbar";
import { useDesigner, useSelection } from "@designer-suite";
import "./curveToolbarButton.scss";
import { updateCurve, getIsWordArtCompatible, getSelectedCurve } from "./curveToolUtilities";

/**
 * This button is responsible for opening the curve tool
 */
export function CurveToolbarButton({ className = "expanding-tool-icon", showIcon = false }) {
    const designer = useDesigner();
    const selection = useSelection("model:change:data");

    if (!designer || !selection || selection.length === 0) {
        return null;
    }

    const selectedCurve = getSelectedCurve(selection);

    if (!getIsWordArtCompatible(designer, selection)) {
        return null;
    }

    const onChange = (selectedValue: string) => updateCurve(designer, selection, selectedValue);
    return (
        <CurveToolbarButtonPresentational
            className={className}
            showIcon={true}
            selectedCurve={selectedCurve}
            onChange={onChange}
        />
    );
}
CurveToolbarButton.displayName = "CurveToolbarButton";
