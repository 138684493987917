import React from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ExpandingButton, ArrangeIcon } from "@shared/features/StudioChrome";
import type { ExpandingButtonProps } from "@shared/features/StudioChrome/ExpandingButton";

const messages = defineMessages({
    buttonLabel: {
        id: "easel.designerSuite.layersButton.label",
        defaultMessage: "Layers Tool",
        description: {}
    },
    buttonTitle: {
        id: "easel.designerSuite.layersButton.title",
        defaultMessage: "Arrange",
        description: {}
    }
});

export interface ArrangeButtonProps {
    onOpen?: ExpandingButtonProps["onOpen"];
    onClose?: ExpandingButtonProps["onClose"];
    content: ExpandingButtonProps["content"];
    showIcon?: boolean;
    className?: string;
    isDisabled?: boolean;
    showArrow?: boolean;
    buttonClassName?: string;
}

/**
 * This button is responsible for opening the layer tool
 */
export function ArrangeButton(props: ArrangeButtonProps) {
    const { t } = useTranslationSSR();
    const { isDisabled, onOpen, content, showIcon, className } = props;

    return (
        <ExpandingButton
            className={className}
            onOpen={onOpen}
            title={t(messages.buttonLabel.id)}
            content={content}
            isDisabled={isDisabled}
            showArrow={false}
            displayType="singlerow"
        >
            {showIcon && <ArrangeIcon aria-hidden={true} />}
            <div className="dcl-ctx-button-label">{t(messages.buttonTitle.id)}</div>
        </ExpandingButton>
    );
}
ArrangeButton.displayName = "ArrangeButton";
