import React from "react";
import classNames from "classnames";
import { Icon } from "@vp/swan";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { itemIsIcon, useActiveCanvas, useDesigner, useSelection } from "@designer-suite";
import { BucketColorIcon, Button } from "@shared/features/StudioChrome";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { getIsRecolorizationCompatible } from "../../../../easel/designer-suite/ToolbarComponents/Buttons/ImageRecolorizationTool";
import * as styles from "./EditImageButton.module.scss";

const messages = defineMessages({
    editImage: {
        id: "studio.toolbar.button.editImage",
        defaultMessage: "Edit image",
        description: {
            note: "Label for a button that opens a panel to edit image color and other options"
        }
    },
    editIcon: {
        id: "studio.toolbar.button.editIcon",
        defaultMessage: "Edit icon",
        description: {
            note: "Label for a button that opens a panel to edit icon color and other options"
        }
    }
});

export function EditImageButton() {
    const { t } = useTranslationSSR();
    const { setCurrentActiveDialog, currentActiveDialog } = useActiveDialog();
    const selection = useSelection();
    const canvas = useActiveCanvas();
    const designer = useDesigner();

    if (!getIsRecolorizationCompatible(designer, selection, canvas)) {
        return null;
    }

    const isIcon = itemIsIcon(selection[0]);

    return (
        <Button
            onClick={() => {
                setCurrentActiveDialog(DialogType.EditImage);
            }}
            className={classNames(styles.editImageButton, {
                [styles.editImageButtonSelected]: currentActiveDialog === DialogType.EditImage
            })}
        >
            {isIcon ? (
                <BucketColorIcon className={styles.editImageButtonIcon} />
            ) : (
                <Icon iconType="image" size="20p" className={styles.editImageButtonIcon} />
            )}
            {isIcon ? t(messages.editIcon.id) : t(messages.editImage.id)}
        </Button>
    );
}
EditImageButton.displayName = "EditImageButton";
