import React from "react";
import { getTrackingDataForSelection, selectedItemsAreOfTypes } from "@utilities";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import { TextAlignmentButton } from "@shared/features/ContextualToolbar";
import { ItemTypes } from "@shared/utils/StudioConfiguration";
import { TextAlignOptionGroup } from "./TextAlignOptionGroup";
import { useDesigner } from "../../designer/DesignerProvider";
import { useSelection } from "../../designer/useSelection";
import { updateSelectedItems } from "../../util";
import type { Designer, ItemSelection } from "../../@types/designer";

function onChange(designer: Designer | undefined, selection: ItemSelection, alignment: Alignment) {
    updateSelectedItems(designer, selection, (item: Item) =>
        item._itemViewModel.model.set("horizontalAlignment", alignment)
    );
}

function getHorizontalAlignment(designer: Designer, selection: ItemSelection) {
    const selectedAlignments = selection.reduce<Alignment[]>((acc, curr: TextItem) => {
        if (acc.includes(curr.horizontalAlignment)) {
            return acc;
        }
        return [...acc, curr.horizontalAlignment];
    }, []);
    return selectedAlignments.length === 1 ? selectedAlignments[0] : "";
}

function getIsCompatible(designer: Designer | undefined, selection: ItemSelection): designer is Designer {
    return designer !== undefined && selectedItemsAreOfTypes(selection, [ItemTypes.TEXT]);
}

const listenEvents = "model:change:horizontalAlignment";

interface Props {
    /** For overriding styles */
    className?: string;
    showIcon?: boolean;
}

/**
 * The button group that enables changing horizontal alignment
 */
export function TextAlignGroup({ className = "expanding-tool-icon", showIcon = false }: Props) {
    const designer = useDesigner();
    const selection = useSelection(listenEvents);

    if (!getIsCompatible(designer, selection) || !selection.length) {
        return null;
    }

    const selectedAlignment = getHorizontalAlignment(designer, selection);

    function onChangeAlignment(alignment: Alignment) {
        onChange(designer, selection, alignment);
    }

    return (
        <TextAlignmentButton
            showIcon={showIcon}
            className={className}
            content={
                <TextAlignOptionGroup
                    className={className}
                    alignment={selectedAlignment}
                    onChange={onChangeAlignment}
                />
            }
            onOpen={() => {
                designer.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_ALIGN, getTrackingDataForSelection(selection));
            }}
        />
    );
}
TextAlignGroup.displayName = "TextAlignGroup";

TextAlignGroup.listenEvents = listenEvents;
TextAlignGroup.getIsCompatible = getIsCompatible;
TextAlignGroup.getHorizontalAlignment = getHorizontalAlignment;
TextAlignGroup.onChange = onChange;
