import React, { useCallback, useEffect } from "react";
import { ArrangeButton as LayersToolExpandingButtonPresentational } from "@shared/features/ContextualToolbar";
import { keyboardShortcutEvents, useKeyboardShortcuts } from "@shared/features/KeyboardShortcuts";
import { LayersControls, Props } from "./LayersControls";

/**
 * This button is responsible for opening the layer tool
 */
export function LayersToolExpandingButton(props: Props) {
    const { className, isDisabled, onOpen, showIcon, ...restOfProps } = props;
    const {
        onClickBringToFront,
        onClickSendToBack,
        onClickBringForward,
        onClickSendBackward,
        disableBringForward = false,
        disableSendBackward = false
    } = restOfProps;
    const { on, off } = useKeyboardShortcuts();

    const bringForwardHandler = useCallback(() => {
        if (!disableBringForward) {
            onClickBringForward();
        }
    }, [disableBringForward, onClickBringForward]);

    const bringToFrontHandler = useCallback(() => {
        if (!disableBringForward) {
            onClickBringToFront();
        }
    }, [disableBringForward, onClickBringToFront]);

    const bringBackwardHandler = useCallback(() => {
        if (!disableSendBackward) {
            onClickSendBackward();
        }
    }, [disableSendBackward, onClickSendBackward]);

    const bringToBackHandler = useCallback(() => {
        if (!disableSendBackward) {
            onClickSendToBack();
        }
    }, [disableSendBackward, onClickSendToBack]);

    useEffect(() => {
        on(keyboardShortcutEvents.arrangeForward, bringForwardHandler);
        on(keyboardShortcutEvents.arrangeBackward, bringBackwardHandler);
        on(keyboardShortcutEvents.arrangeToFront, bringToFrontHandler);
        on(keyboardShortcutEvents.arrangeToBack, bringToBackHandler);
        return () => {
            off(keyboardShortcutEvents.arrangeForward, bringForwardHandler);
            off(keyboardShortcutEvents.arrangeBackward, bringBackwardHandler);
            off(keyboardShortcutEvents.arrangeToFront, bringToFrontHandler);
            off(keyboardShortcutEvents.arrangeToBack, bringToBackHandler);
        };
    }, [on, off, bringForwardHandler, bringToFrontHandler, bringBackwardHandler, bringToBackHandler]);

    return (
        <LayersToolExpandingButtonPresentational
            onOpen={onOpen}
            className="expanding-tool-icon"
            content={<LayersControls showHorizontalDividers={true} {...restOfProps} />}
            showIcon={showIcon}
            {...restOfProps}
        />
    );
}
LayersToolExpandingButton.displayName = "LayersToolExpandingButton";
