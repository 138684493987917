import React from "react";
import { FontFamilyButtonStudio5 } from "@presentational";
import { useDesigner, useFonts } from "@designer-suite";
import { STUDIO_TRACKING_EVENTS } from "@shared/utils/Tracking";
import {
    updateSelectedItems,
    getTrackingDataForSelection,
    getTeamsSelectedFont,
    teamsPlaceholderFilter,
    updateTeamsItemData
} from "@utilities";
import { useSelection } from "../../designer/useSelection";

export function TeamsFontFamilyButton({ showArrow = true, className = "expanding-tool-icon" }) {
    const designer = useDesigner();
    const selection = useSelection("model:change:data");
    const { teamsPlaceholderItems, otherItems } = teamsPlaceholderFilter(selection);
    const fonts = useFonts();

    if (!designer || !teamsPlaceholderItems.length || otherItems.length) {
        return null;
    }

    const selectedFontFamily = getTeamsSelectedFont(teamsPlaceholderItems);

    function onSelectFont(fontFamily: string, inRecentFonts: boolean) {
        designer?.eventBus.trigger(STUDIO_TRACKING_EVENTS.CLICK_FONT_CHOICE, {
            ...getTrackingDataForSelection(selection),
            fontFamily,
            inRecentFonts
        });
        updateSelectedItems(designer, teamsPlaceholderItems, teamsPlaceholder => {
            updateTeamsItemData(teamsPlaceholder, { fontFamily });
        });
    }

    return (
        <FontFamilyButtonStudio5
            onSelectFont={onSelectFont}
            selectedFontFamily={selectedFontFamily}
            fonts={fonts}
            showArrow={showArrow}
            className={className}
        />
    );
}
TeamsFontFamilyButton.displayName = "TeamsFontFamilyButton";
