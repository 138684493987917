import React, { ReactNode, Fragment } from "react";

interface Props {
    /** Child elements
     * Wrapping groups of buttons
     */
    children?: ReactNode;
}

/**
 * A group of related tools
 */
export function ToolGroup({ children }: Props) {
    return <Fragment>{children}</Fragment>;
}
ToolGroup.displayName = "ToolGroup";
