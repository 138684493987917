import React, { useCallback } from "react";
import { useDesigner, useSelection } from "@designer-suite";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { IconButton } from "@shared/features/ContextualToolbar";
import { ColorIconType } from "@presentational";
import { useActiveDialog, DialogType } from "@shared/features/ActiveDialog";
import { getIsFillColorCompatible } from "./colorUtil";
import { getIcon } from "../../presentational/ColorPicker/getIcon";

const messages = defineMessages({
    fillColorPicker: {
        id: "easel.ui.wordArtAndTextColorPicker.fillColorPicker",
        defaultMessage: "Fill color",
        description: {
            note: "Label for a button that allows for changing fill colors"
        }
    }
});

/**
 * Responsible for changing the fill color on shapes
 */
export function FillColorPicker() {
    const { t } = useTranslationSSR();
    const designer = useDesigner();
    const selection = useSelection("model:change:fillColor");
    const { setCurrentActiveDialog } = useActiveDialog();
    const colorIcon = getIcon(ColorIconType.ShapeFillColorIcon);
    const canPickColor = getIsFillColorCompatible(designer, selection);
    const onClick = useCallback(() => {
        setCurrentActiveDialog(DialogType.FillColorPicker);
    }, [setCurrentActiveDialog]);

    if (!canPickColor) {
        return null;
    }

    return (
        <IconButton
            ariaLabel={t(messages.fillColorPicker.id)}
            icon={colorIcon}
            onClick={onClick}
            className={"colorpicker-button"}
        />
    );
}
FillColorPicker.displayName = "FillColorPicker";

// FillColorPicker.getListenEvents = listenEvents;
// FillColorPicker.getFirstColor = getFirstColor;
// FillColorPicker.getCurrentColor = getCurrentColor;
// FillColorPicker.onApply = onApply;
