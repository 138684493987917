import React, { forwardRef, ReactNode } from "react";
import { defineMessages, useTranslationSSR } from "@vp/i18n-helper";
import { ExpandingButton, TableDesignIcon, type ExpandingButtonHandles } from "@shared/features/StudioChrome";

const messages = defineMessages({
    buttonLabel: {
        id: "easel.designerSuite.tableDesignSelectorButton.label",
        defaultMessage: "Table Style",
        description: {}
    }
});

interface TableDesignSelectorButtonProps {
    className?: string;
    isDisabled?: boolean;
    showIcon?: boolean;
    content: ReactNode;
}

export const TableDesignSelectorButton = forwardRef<ExpandingButtonHandles, TableDesignSelectorButtonProps>(
    (props, ref) => {
        const { t } = useTranslationSSR();
        const { className = "expanding-tool-icon", showIcon, content, isDisabled } = props;

        return (
            <ExpandingButton
                className={className}
                ref={ref}
                isDisabled={isDisabled}
                content={content}
                showArrow={false}
                displayType="multirow"
            >
                {showIcon && <TableDesignIcon aria-hidden={true} />}
                <div className="dcl-ctx-button-label">{t(messages.buttonLabel.id)}</div>
            </ExpandingButton>
        );
    }
);

TableDesignSelectorButton.displayName = "TableDesignSelectorButton";
