import React from "react";
import { RotateSelectionButton } from "@shared/features/ContextualToolbar";
import { STUDIO_TRACKING_EVENTS, fireDesignToolTrackingEvent } from "@shared/utils/Tracking";
import { useSelection } from "@easel";
import { getTrackingDataForSelection } from "@utilities";
import { RotationSlider } from "../ToolbarComponents/RotateSlider/RotationSlider";

interface RotateToolProps {
    /** For overriding styles */
    className?: string;
    /** Whether the button should only return the the icon
     * @default false
     */
    showIcon?: boolean;
}

/**
 * A very simple rotate button wired up to designer
 */
export function RotateTool({ className, showIcon }: RotateToolProps) {
    const selection = useSelection();

    const onOpen = () => {
        fireDesignToolTrackingEvent({
            eventDetail: STUDIO_TRACKING_EVENTS.CLICK_ROTATE_OPEN,
            label: "Click opening the Rotation tool",
            extraData: () => getTrackingDataForSelection(selection)
        });
    };

    if (selection.length > 1) {
        return null;
    }

    return (
        <RotateSelectionButton className={className} showIcon={showIcon} onOpen={onOpen} content={<RotationSlider />} />
    );
}
RotateTool.displayName = "RotateTool";
